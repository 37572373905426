import { useSession } from 'next-auth/react'

import Layout from '@/components/Layout'
import Login from '@/components/Login'

function Index() {
  const { data: session } = useSession()

  return (
    <Layout>
      <Login />
      <pre>{JSON.stringify(session, null, 2)}</pre>
    </Layout>
  )
}

export default Index
