import { ReactNode, FC } from 'react'

import Nav from './components/Nav'
import Footer from './components/Footer'

type LayoutProps = {
  children?: ReactNode
}

const Layout: FC<LayoutProps> = (props) => {
  return (
    <div className="min-h-screen">
      <Nav />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 min-h-full">
        {props.children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
