import { FC } from 'react'

const Footer: FC = () => {
  return (
    <footer className="footer footer-center p-4 text-base-content">
      <div>
        <p>
          Copyright © {new Date().getFullYear()}&nbsp;GZPZ&nbsp;
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            粤ICP备2022085187号
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
